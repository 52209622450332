exports.components = {
  "component---src-pages-404-en-us-js": () => import("./../../../src/pages/404.en-US.js" /* webpackChunkName: "component---src-pages-404-en-us-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-404-nl-nl-js": () => import("./../../../src/pages/404.nl-NL.js" /* webpackChunkName: "component---src-pages-404-nl-nl-js" */),
  "component---src-pages-advertising-en-us-js": () => import("./../../../src/pages/advertising.en-US.js" /* webpackChunkName: "component---src-pages-advertising-en-us-js" */),
  "component---src-pages-contact-en-us-js": () => import("./../../../src/pages/contact.en-US.js" /* webpackChunkName: "component---src-pages-contact-en-us-js" */),
  "component---src-pages-forbes-press-room-en-us-js": () => import("./../../../src/pages/forbes-press-room.en-US.js" /* webpackChunkName: "component---src-pages-forbes-press-room-en-us-js" */),
  "component---src-pages-index-en-us-js": () => import("./../../../src/pages/index.en-US.js" /* webpackChunkName: "component---src-pages-index-en-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-nl-nl-js": () => import("./../../../src/pages/index.nl-NL.js" /* webpackChunkName: "component---src-pages-index-nl-nl-js" */),
  "component---src-pages-privacy-policy-en-us-js": () => import("./../../../src/pages/privacy-policy.en-US.js" /* webpackChunkName: "component---src-pages-privacy-policy-en-us-js" */),
  "component---src-pages-subscribe-en-us-js": () => import("./../../../src/pages/subscribe.en-US.js" /* webpackChunkName: "component---src-pages-subscribe-en-us-js" */),
  "component---src-pages-thank-you-en-us-js": () => import("./../../../src/pages/thank-you.en-US.js" /* webpackChunkName: "component---src-pages-thank-you-en-us-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-sub-categories-js": () => import("./../../../src/templates/subCategories.js" /* webpackChunkName: "component---src-templates-sub-categories-js" */)
}

