import './src/styles/fonts/Georgia.ttf'
import './src/styles/arrows.css'
import './src/styles/global.css'
import './src/styles/zarrows.css'
import 'firebase/database'

import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"

require('typeface-merriweather')
require('typeface-work-sans')
